import React from "react";
import Icons from "../../../assets/icons";
import NavLogo from "../Navbar/components/NavLogo";
import FooterItems from "./components/FooterItems";
import Socials from "../../Partials/Socials";

export default function Footer() {
  return (
    <footer className="font-inter bg-secondary-light py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <hr className="my-6 border-gray-300  border-[1px]" />

        <div className="flex flex-col space-y-5">
          {/* Logo/Company Name */}
          <div className="mb-4 md:mb-0 flex items-center space-x-5">
            <NavLogo />
            <Icons src={"arrowRight"} />
            <span className="font-bold text-xs">Hutter Products</span>
          </div>
          <FooterItems />
          {/* Links */}
          {/* <div className="flex space-x-6">
            <a
              href="#"
              className="text-gray-300 hover:text-white text-sm font-medium"
            >
              Home
            </a>
            <a
              href="#"
              className="text-gray-300 hover:text-white text-sm font-medium"
            >
              About Us
            </a>
            <a
              href="#"
              className="text-gray-300 hover:text-white text-sm font-medium"
            >
              Services
            </a>
            <a
              href="#"
              className="text-gray-300 hover:text-white text-sm font-medium"
            >
              Contact
            </a>
          </div> */}
        </div>
        {/* Divider */}
        <hr className="my-6 border-gray-300 border-[1px]" />
        {/* Copyright and Social Links */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Copyright */}
          <p className="font-normal text-xs text-secondary-dark">
            Copyright &copy; {new Date().getFullYear()} Hutter Products GMBH.
            All rights reserved.
          </p>
          {/* Social Links */}
          <Socials />
        </div>
      </div>
    </footer>
  );
}
