import React from "react";
import Icons from "../../assets/icons";

export default function Socials() {
  return (
    <div className="mt-4 md:mt-0 flex items-center gap-5">
      <a href="https://twitter.com">
        <Icons src={"twitter"} />
      </a>
      <a href="https://facebook.com">
        <Icons src={"facebook"} />
      </a>
      <a href="https://pinterest.com">
        <Icons src={"pinterest"} />
      </a>
      <a href="https://youtube.com">
        <Icons src={"youtube"} />
      </a>

      <a href="https://instagram.com">
        <Icons src={"instagram"} />
      </a>
      <a href="https://linkedin.com">
        <Icons src={"linkedin"} />
      </a>
    </div>
  );
}
