import React from "react";
import Icons from "../../../../assets/icons";
import { Link } from "react-router-dom";

export default function NavItems() {
  const menu = [
    "shop",
    "configurator",
    "events",
    "clubwear",
    "workwear",
    "retail",
    "packaging",
    "gifts",
    "hospitality",
    "support",
  ];
  return (
    <div className="hidden md:block">
      <div className="ml-10 flex items-baseline space-x-10">
        {menu.map((name, index) => (
          <Link key={index} to={`/${name}`}>
            <span className="text-primary font-normal text-xs capitalize">{name}</span>
          </Link>
        ))}
         <a href="#" className="flex items-center space-x-1">
            <span className="text-primary font-normal text-xs">EN</span>
            <Icons src={"arrowDownFill"}/>
        </a>
      </div>
    </div>
  );
}
