import React from "react";
import Icons from "../../../../assets/icons";

export default function NavIcons() {
  return (
    <div className="hidden md:block">
      <div className="ml-10 flex items-center space-x-10">
      <a href="#">
            <Icons
                src={"person2"}
            />
        </a>
        <a href="#">
            <Icons
                src={"search"}
            />
        </a>
        <a href="#">
            <Icons
                src={"bag"}
            />
        </a>
      </div>
    </div>
  );
}
