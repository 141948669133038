import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout"; // Layout includes Header/Footer
import Products from "../pages/Product";

// Lazy-loaded pages
const Login = lazy(() => import("../pages/Login"));
const SignUp = lazy(() => import("../pages/SignUp"));
const Customizer = lazy(() => import("../pages/Customizer"));
const Overview = lazy(() => import("../pages/Overview"));
const Shop = lazy(() => import("../pages/Shop"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Product = lazy(() => import("../components/Dashboard/Product"));
const Home = lazy(() => import("../pages/Home"));
// const Home = lazy(() => import('../pages/Home'));
// const About = lazy(() => import('../pages/About'));
// const Products = lazy(() => import('../pages/Products'));
// const NotFound = lazy(() => import('../pages/NotFound'));

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="login" element={<Login />}></Route>
          <Route path="signup" element={<SignUp />}></Route>
          <Route path="configurator/:id" element={<Customizer />} />
          <Route path="dashboard" element={<Dashboard />}>
            <Route path="product" element={<Product />} />
          </Route>
          {/* The Layout is a route element */}
          <Route path="/" element={<Layout />}>
            {/* Child routes use Outlet in Layout */}
            <Route index element={<Shop />} />
            <Route path="shop" element={<Shop />} />
            <Route path="overview/:id" element={<Overview />} />
            {/* Add other routes here */}
            <Route path="/home" element={<Home />} />
            <Route path="/products" element={<Products />} />
            {/* <Route path="about" element={<About />} />
            <Route path="products" element={<Products />} />
            <Route path="*" element={<NotFound />} /> */}
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
