// TShirtCustomizer.jsx
import React, { useState } from "react";
import { ChevronRight, Info, Share2 } from "lucide-react";
import test from "../assets/homeImages/Frame 2085662061 (1).png";

const InfoBox = ({ title, description, className = "" }) => (
  <div
    className={`border border-gray-200 rounded-lg p-4 bg-white ${className}`}
  >
    <div className="flex justify-between items-start">
      <div>
        <h3 className="text-sm font-semibold mb-1">{title}</h3>
        <p className="text-xs text-gray-600">{description}</p>
      </div>
      <Info className="w-4 h-4 text-gray-600 flex-shrink-0" />
    </div>
  </div>
);

const QuantityRow = ({ quantity, pricePerPiece, total }) => (
  <div className="flex justify-between py-2 text-xs">
    <span>{quantity}</span>
    <span className="text-gray-500">${pricePerPiece}/piece</span>
    <span className="text-gray-500">${total}</span>
  </div>
);

const SizeButton = ({ size, chest }) => (
  <div className="text-center">
    <button className="w-full border rounded p-2 text-sm hover:border-blue-500 transition-colors">
      {size}
    </button>
    <span className="text-xs text-gray-500 mt-1 block">{chest}</span>
  </div>
);

const MaterialButton = ({ type }) => (
  <button className="border border-gray-200 rounded-lg p-4 text-left hover:border-blue-500 bg-white transition-colors">
    <div className="text-sm font-medium">Polyester</div>
    <div className="text-xs text-gray-600">{type} Polyester</div>
  </button>
);

const Products = () => {
  const [quantity, setQuantity] = useState(20);
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [1, 2, 3, 4, 5];
  const colorVariants = [
    ["white", "blue", "navy", "green"],
    ["white", "lightblue", "red", "green"],
  ];
  const inspirationVariants = [
    ["lightblue", "white", "blue", "navy"],
    ["blue", "lightblue", "blue", "green"],
  ];

  const sizeChart = {
    women: [
      { size: "XS", chest: "31/34" },
      { size: "S", chest: "34/36" },
      { size: "M", chest: "36/38" },
      { size: "L", chest: "38/40" },
      { size: "XL", chest: "40/42" },
      { size: "2XL", chest: "42/44" },
      { size: "3XL", chest: "44/46" },
    ],
  };

  const quantityPrices = [
    { quantity: 20, pricePerPiece: 10.0, total: 50.4 },
    { quantity: 40, pricePerPiece: 8.73, total: 69.12 },
    { quantity: 60, pricePerPiece: 7.75, total: 86.4 },
    { quantity: 80, pricePerPiece: 6.94, total: 195.12 },
    { quantity: 100, pricePerPiece: 5.75, total: 175.68 },
  ];

  const materialTypes = ["Recycled", "Dry-blend", "Bio based", "CGI Generated"];

  return (
    <div className="max-w-7xl mx-auto px-4">
      {/* Info Bars */}
      <div className="bg-gray-50 rounded-lg mb-4 p-3 text-sm flex justify-between items-center">
        <span>Earn up to 10% back in loyalty points with every order</span>
        <span className="cursor-pointer">+</span>
      </div>
      <div className="bg-gray-50 rounded-lg mb-6 p-3 text-sm flex justify-between items-center">
        <span>Subscribe for premium 3D renders and marketing materials</span>
        <span className="cursor-pointer">+</span>
      </div>

      {/* Header */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-1">By You</h1>
        <p className="text-sm text-gray-600">
          Custom Polyester T-Shirt Designed By You
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Left Column - Product Image */}
        <div className="relative">
          <div className="relative">
            <div className="absolute top-4 left-4 flex gap-2 z-10">
              <button className="bg-white p-2 rounded-full shadow-sm">
                <Share2 className="w-4 h-4" />
              </button>
            </div>
            <div className="bg-gray-50 rounded-lg p-8 flex items-center justify-center mb-4">
              <img
                src={test}
                alt="T-shirt design"
                className="max-w-full h-auto"
              />
              <button className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
          </div>

          {/* Dot Navigation */}
          <div className="flex justify-center gap-2">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-2 h-2 rounded-full transition-colors ${
                  currentSlide === index ? "bg-black" : "bg-gray-300"
                }`}
              />
            ))}
          </div>
        </div>

        {/* Right Column - Customization Options */}
        <div>
          {/* Style Section */}
          <div className="mb-8">
            <h2 className="text-sm font-normal mb-4">
              Style. Choose your design.
            </h2>
            <div className="space-y-2">
              <button className="w-full text-left border rounded-lg p-4 hover:border-blue-500 transition-colors">
                Customize
              </button>
              <button className="w-full text-left border rounded-lg p-4 hover:border-blue-500 transition-colors">
                Your Designs
              </button>
            </div>

            {/* Color Variants Grid */}
            <div className="mt-4 space-y-4">
              {colorVariants.map((row, rowIndex) => (
                <div key={rowIndex} className="grid grid-cols-4 gap-2">
                  {row.map((color, colIndex) => (
                    <div key={colIndex} className="aspect-square">
                      <img
                        src={test}
                        alt={`Variant ${rowIndex}-${colIndex}`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>

            {/* Inspirations Section */}
            <div className="mt-4">
              <h3 className="text-sm font-normal mb-4">Inspirations</h3>
              {inspirationVariants.map((row, rowIndex) => (
                <div key={rowIndex} className="grid grid-cols-4 gap-2 mb-2">
                  {row.map((color, colIndex) => (
                    <div key={colIndex} className="aspect-square">
                      <img
                        src={test}
                        alt={`Inspiration ${rowIndex}-${colIndex}`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <InfoBox
              title="Need Help With Your Design?"
              description="Upload your reference image and we'll create the exact design you need ready for you to personalize and magical."
              className="mt-4"
            />
          </div>

          {/* Quantity Section */}
          <div className="mb-8">
            <h2 className="text-sm font-normal mb-4">
              Quantity. Choose the exact amount you'll need.
            </h2>
            <div className="relative">
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
                className="w-full border rounded-lg p-4 pr-20"
                min="20"
                step="20"
              />
              <div className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500">
                <span className="text-sm">$10/piece</span>
                <span className="ml-2 font-semibold">$200</span>
              </div>
            </div>

            <div className="mt-4">
              {quantityPrices.map((price) => (
                <QuantityRow
                  key={price.quantity}
                  quantity={price.quantity}
                  pricePerPiece={price.pricePerPiece.toFixed(2)}
                  total={price.total.toFixed(2)}
                />
              ))}
            </div>
          </div>

          {/* Size Section */}
          <div className="mb-8">
            <h2 className="text-sm font-normal mb-4">
              Size. Pick the perfect fit.
            </h2>
            <div className="space-y-4">
              <button className="w-full text-left border rounded-lg p-4 hover:border-blue-500 transition-colors">
                Women
              </button>
              <div className="grid grid-cols-7 gap-2">
                {sizeChart.women.map((size) => (
                  <SizeButton
                    key={size.size}
                    size={size.size}
                    chest={size.chest}
                  />
                ))}
              </div>
              <button className="w-full text-left border rounded-lg p-4 hover:border-blue-500 transition-colors">
                Men
              </button>
            </div>

            <InfoBox
              title="Need Help With Your Sizes?"
              description="Get a better understanding of how to choose the sizes."
              className="mt-4"
            />
          </div>

          {/* Finish Section */}
          <div className="mb-8">
            <h2 className="text-sm font-normal mb-4">
              Finish. Pick your favorite material
            </h2>
            <div className="grid grid-cols-2 gap-4">
              {materialTypes.map((type) => (
                <MaterialButton key={type} type={type} />
              ))}
            </div>

            <InfoBox
              title="Want To Learn More About This Product?"
              description="Apple Pencil has set the standard for how drawing, painting, handwriting, and note-taking should feel — intuitive, precise, and magical."
              className="mt-4"
            />
          </div>

          {/* Price and Add to Bag */}
          <div className="mt-8">
            <div className="mb-4">
              <div className="text-2xl font-bold">$1,187.00</div>
              <div className="text-sm">Customized Volunteer T-Shirt</div>
              <div className="text-xs text-gray-500 flex items-center gap-2 mt-1">
                <span className="w-1.5 h-1.5 bg-gray-500 rounded-full"></span>
                Produced in: 18 Days
              </div>
            </div>
            <button className="w-full bg-black text-white rounded-lg py-4 px-8 flex items-center justify-center gap-2 text-sm hover:bg-gray-900 transition-colors">
              Add to Bag
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
