import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

// Async thunk to fetch all materials
export const fetchMaterials = createAsyncThunk(
  'assets/fetchMaterials',
  async () => {
    const response = await axios.get(`${SERVER_URL}/api/v1/assets/materials`);
    return response.data; 
  }
);

export const fetchModels = createAsyncThunk(
  'assets/fetchModels',
  async () => {
    const response = await axios.get(`${SERVER_URL}/api/v1/assets/models`);
    return response.data;
  }
);

// Slice
const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    models: {},
    materials: {},  
    materialStatus: 'idle',
    modelStatus: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterials.pending, (state) => {
        state.materialStatus = 'loading';
      })
      .addCase(fetchMaterials.fulfilled, (state, action) => {
        state.materialStatus = 'succeeded';
        state.materials = action.payload;
      })
      .addCase(fetchMaterials.rejected, (state, action) => {
        state.materialStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchModels.pending, (state) => {
        state.modelStatus = 'loading';
      })
      .addCase(fetchModels.fulfilled, (state,action) => {
        state.modelStatus = 'succeeded';
        state.models = action.payload;
      })
      .addCase(fetchModels.rejected, (state, action) => {
        state.modelStatus = 'failed';
        state.error = action.error.message;
      })
  },
});

export default assetsSlice.reducer;
