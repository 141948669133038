import { configureStore } from "@reduxjs/toolkit";
import customizerReducer from "./customizerSlice"; 
import productReducer from "./productSlice";
import assetsReducer from "./assetsSlice"
export const store = configureStore({
  reducer: {
    customizer: customizerReducer,
    products: productReducer,
    assets: assetsReducer,
  },
});
