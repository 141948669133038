import React from "react";

export default function FooterItems() {
  const menu = [
    {
      title: "About Hutter Products",
      links: [
        "Company Details",
        "Career",
        "Blogs",
        "Size Guide",
        "Terms of Use",
        "Terms of Sale",
        "Privacy & Cookie Settings",
        "Privacy & Cookie Policy",
      ],
    },
    {
      title: "Shop",
      links: [
        "Bags",
        "Hats & Headwear",
        "Tops & T-Shirt",
        "Shoes",
        "Pants & Tights",
        "Bottles",
        "by Sports",
        "Accessories",
        "Socks",
        "Sunglasses",
        "Hoodies",
        "Shorts",
      ],
    },
    {
      title: "Events",
      links: [
        "Festivals", 
        "Sport Events", 
        "Fair",
        "Volunteers",
        "Work Uniform",
        "Corporate Gifts",
        "Retail",
        "Gym",
        "Hospital",
        "Yoga"
    ],
    },
    {
        title: "Clubs",
        links: [
            "Football",
            "Cycling",
            "Gymnastics",
            "Tennis",
            "Basketball",
            "Handball",
            "Volleyball",
            "Rugby",
            "Floorball",
            "Running"
        ]
    },
    {
        title: "Sustainability",
        links: [
            "Sustainability Commitment",
            "Eco-Friendly Materials",
            "Sustainability Manufacturing",
            "Carbon Footprint",
            "Reimagine, Reuse, Recycle",
            "Certifications and Standards",
            "Innovative Practices",
            "Customer Involvement",
            "Real Impace"
        ]
    },
    {
        title: "Account",
        links: [
            "Manage your account",
            "Track Order",
            "Wishlist"
        ]
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
      {menu.map((item, index) => (
        <div key={index} className={`flex flex-col space-y-3  h-min ${index === menu.length - 1 ? 'md:mt-[-50%]':''}`}>
          <span className="font-medium text-primary text-xs mb-2">
            {item.title}
          </span>
          {item.links.map((link, linkIndex) => (
            <span key={linkIndex} className="font-light text-primary-light text-xs">
              {link}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
}
