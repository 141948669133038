  import React from "react";
  import AppRoutes from "./routes";
  import { Provider } from "react-redux";
  import { store } from "./store/store";


  export default function App() {
    return (
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    );
  }
