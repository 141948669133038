import React from "react";
import Icons from "../../../../assets/icons";

export default function NavLogo() {
  return (
    <div className="flex items-center">
      <a href="/">
        <Icons src={"hutter"} />
      </a>
    </div>
  );
}
    